import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Suspense
} from 'react';
import { executeNewToolbarCommand } from '../../components/LookaheadFilterHeader/index.interface';
import MenuSubmodules from '../../components/MenuSubmodules';
import { TimelineSettings } from '../../components/TimelineSettings/TimelineSettings';
import { useParams } from 'react-router-dom';
import { ViewsCtx } from '../../Context/Planification/ViewsContext';
import { useSelector } from 'react-redux';
import { getSectorCompanyAndProject } from '../../analytics/utils';
import { userService } from '../../services';
import {
  mappedUserToDataSource,
  trackingEventAddUsersButton
} from '../../utils/addUsersModalsUtils';

import { isFeatureOn } from '../../utils/featureUtils';

const ModalAssignUser = React.lazy(
  () => import('../../components/Settings/ModalAssignUser')
);
const ModalAddUsers = React.lazy(
  () => import('../../components/ModalAddUsers/ModalAddUsers')
);

const LookaHeadSubmodulesMenu = (props) => {
  const {
    history = {},
    project = null,
    projectSelected = {},
    lookaheadPermissions = {},
    t
  } = props;

  const [message, setMessage] = useState('');
  const [visibleAssignUser, setVisibleAssignUser] = useState(false);
  const [usersByCompany, setUsersByCompany] = useState([]);
  const [isReloadTableUsers, setIsReloadTableUsers] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isShowAddUserModal, setIsShowAddUserModal] = useState(false);
  const { currentCompany: sessionCompany } = getSectorCompanyAndProject();
  const [Comp, setComp] = useState({ Comp: null, props: null });
  const projectState = useSelector((state) => state.projectState);

  const {
    projectIdParam,
    sectorIdParam,
    idsParam,
    projectIdPlan,
    sectorIdPlan
  } = useParams();
  const { current: messageOptions } = useRef({
    DURATION: 'info_criteria_task_duration',
    COST: 'info_criteria_task_cost',
    HH: 'info_criteria_task_hh'
  });

  const {
    viewCtx: [view],
    ganttObject: [gantt]
  } = useContext(ViewsCtx);

  useEffect(() => {
    handleGetUsersProject();
  }, [projectState.projectSelected]);
  useEffect(() => {
    if (isReloadTableUsers) {
      handleGetUsersProject();
      setIsReloadTableUsers(false);
    }
  }, [isReloadTableUsers]);
  useEffect(() => {
    loadUsersByCompany();
  }, [projectState.projectSelected]);

  useEffect(() => {
    projectSelected &&
      setMessage(
        t(messageOptions[projectSelected?.task_creter?.toUpperCase()])
      );
  }, [projectSelected]);

  useEffect(() => {
    setComp({
      Comp: TimelineSettings,
      props: {
        includeSettings: view?.name === 'gantt' && isFeatureOn('lwp-15'),
        t,
        onCommandDispatched: onTimelineSettingsCommandDispatched,
        timelineObject: gantt,
        messageModule: t(
          messageOptions[projectSelected.task_creter.toUpperCase()]
        )
      }
    });
  }, [view]);

  const handleGetUsersProject = async () => {
    try {
      const users = await userService.getUsersProjectSettings(
        projectState.projectSelected
      );
      const parsedUsers = users?.users
        .filter((user) => user.enabling_status)
        .map((user) => mappedUserToDataSource(user, t));
      setDataSource(parsedUsers);
    } catch {
      setDataSource([]);
    }
  };

  const loadUsersByCompany = async () => {
    if (!sessionCompany?.id) {
      return;
    }

    const usersByCompany = await userService.getByCompany(sessionCompany.id);
    setUsersByCompany(usersByCompany.users);
  };

  const onTimelineSettingsCommandDispatched = (command, value) => {
    const ADD_USERS_COMMANDS = {
      OPEN_ADD_USERS_MODAL: () => setIsShowAddUserModal(true),
      OPEN_ASSIGN_USERS_MODAL: () => setVisibleAssignUser(true),
      GANTT_ADD_USERS_ITEMS: () => trackingEventAddUsersButton()
    };
    const addUsersCommand = ADD_USERS_COMMANDS[command];
    if (addUsersCommand) {
      addUsersCommand();
      return;
    }

    if (command.includes('SET_DATE_FORMAT_')) {
      value = command.split('SET_DATE_FORMAT_')[1];
      command = 'DATE_FORMAT';
    }
    executeNewToolbarCommand(command, value);
  };

  const publishSubmodules = ({ history, match, t, toggleActiveButtons }) => {
    const invalidProject = !project?.project.geo_project_id;
    const buttons = invalidProject
      ? [
          {
            id: 'plan',
            title: t('tabs.lookahead.plan'),
            routeName: `${match.url}/planification`,
            onClick: (_) => {
              toggleActiveButtons(1);
              if (
                projectIdParam ||
                sectorIdParam ||
                idsParam ||
                projectIdPlan ||
                sectorIdPlan
              ) {
                history.push('/lookahead/planification');
              } else {
                history.push(`${match.url}/planification`);
              }
            },
            hide: lookaheadPermissions?.plan === 'SA'
          },
          {
            id: 'constraints',
            title: t('tabs.lookahead.constraints'),
            routeName: `${match.url}/constraints`,
            onClick: (_) => {
              toggleActiveButtons(2);
              if (
                projectIdParam ||
                sectorIdParam ||
                idsParam ||
                projectIdPlan ||
                sectorIdPlan
              ) {
                history.push('/lookahead/constraints');
              } else {
                history.push(`${match.url}/constraints`);
              }
            },
            hide: lookaheadPermissions?.constraints === 'SA'
          }
        ]
      : [
          {
            id: 'plan',
            title: t('tabs.lookahead.plan'),
            routeName: `${match.url}/planification`,
            onClick: (_) => {
              toggleActiveButtons(1);
              if (
                projectIdParam ||
                sectorIdParam ||
                idsParam ||
                projectIdPlan ||
                sectorIdPlan
              ) {
                history.push('/lookahead/planification');
              } else {
                history.push(`${match.url}/planification`);
              }
            },
            hide: lookaheadPermissions?.plan === 'SA'
          },
          {
            id: 'constraints',
            title: t('tabs.lookahead.constraints'),
            routeName: `${match.url}/constraints`,
            onClick: (_) => {
              toggleActiveButtons(2);
              if (
                projectIdParam ||
                sectorIdParam ||
                idsParam ||
                projectIdPlan ||
                sectorIdPlan
              ) {
                history.push('/lookahead/constraints');
              } else {
                history.push(`${match.url}/constraints`);
              }
            },
            hide: lookaheadPermissions?.constraints === 'SA'
          }
        ](projectIdParam && sectorIdParam) &&
        buttons.map((el) => {
          el.id === 'constraints' && (el.className = 'lookahead-btn-style');
          return { ...el };
        })(projectIdPlan && sectorIdPlan) &&
        buttons.map((el) => {
          el.id === 'plan' && (el.className = 'lookahead-btn-style');
          return { ...el };
        });

    return buttons;
  };

  return (
    <div>
      <MenuSubmodules
        publishSubmodules={publishSubmodules}
        history={history}
        messageModule={message}
        CustomRightComp={Comp}
      />
      <Suspense
        fallback={
          <div>{t('settings.project_settings.users_tab.suspense_loading')}</div>
        }>
        <ModalAssignUser
          visible={visibleAssignUser}
          setVisible={setVisibleAssignUser}
          setReloadTableUsers={setIsReloadTableUsers}
          reloadTableUsers={isReloadTableUsers}
          usersByCompany={usersByCompany}
          usersProject={dataSource}
        />
      </Suspense>
      <Suspense
        fallback={
          <div>{t('settings.project_settings.users_tab.suspense_loading')}</div>
        }>
        <ModalAddUsers
          isShow={isShowAddUserModal}
          setIsShow={setIsShowAddUserModal}
          projectAssigned={[{ value: projectState.projectSelected }]}
          setReloadTableUsers={setIsReloadTableUsers}
        />
      </Suspense>
    </div>
  );
};

export default LookaHeadSubmodulesMenu;

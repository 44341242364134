import React, { useState, useEffect } from 'react';
import EventEmitter from 'react-native-eventemitter';
import { Animated } from 'react-animated-css';
/** library to redirect  */
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';

import { Alert } from 'antd';

import PlanificationView from './planification';
import ResourcesView from './resources';
import ConstraintsView from './constraints';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useLookaheadPermissions from '../../hooks/useLookaheadPermissions';
import { useSelector, useDispatch } from 'react-redux';
import { projectService } from '../../services/project.service';
import './index.css';
import { withTranslation } from 'react-i18next';
import * as projectActions from '../../redux/slices/projectSlice';
import LookaHeadSubmodulesMenu from './LookaheadMenuOption';

import ViewsProvider from '../../Context/Planification/ViewsContext';
import { TimerManagerSingleton } from '../../utils/timerManager';

export const TestIds = {
  LOOKAHEAD_MAIN_VIEW_CONTAINER: 'LOOKAHEAD_MAIN_VIEW_CONTAINER'
};

function LookaheadMainView({ t = () => {}, history = () => {} }) {
  const timerManager = TimerManagerSingleton.getInstance();
  const dispatch = useDispatch();

  const {
    projectIdParam,
    sectorIdParam,
    idsParam,
    projectIdPlan,
    sectorIdPlan
  } = useParams();

  const { height } = useWindowDimensions();

  const lookaheadPermissions = useLookaheadPermissions();

  const match = useRouteMatch();
  const [userIsOwner, setUserIsOwner] = useState(true);
  const [project, setProject] = useState(null);
  const [_, setContainerHeader] = useState();
  const projectState = useSelector((state) => state.projectState);
  const [hidePlanification, setHidePlanification] = useState(true);

  let projectSelected = projectState.allProjects.find(
    (el) => el.id == projectState.projectSelected
  );

  useEffect(() => {
    if (hidePlanification) {
      const hidePlanificationCallback = () => {
        setHidePlanification(false);
      };
      timerManager.registerAutoTimeout(
        hidePlanificationCallback,
        500,
        'hidePlanification'
      );
    }
  }, [hidePlanification]);

  /** Hide planning once change view is applied */
  useEffect(() => {
    const hidePlanificationCallback = () => {
      setHidePlanification(true);
    };
    EventEmitter.on('HIDE_PLANIFICATION', hidePlanificationCallback);

    return () => {
      EventEmitter.removeListener(
        'HIDE_PLANIFICATION',
        hidePlanificationCallback
      );
    };
  }, []);

  useEffect(() => {
    window.Appcues.page();
    projectService
      .show(projectSelected?.id)
      .then((project) => setProject(project));
  }, []);

  useEffect(() => {
    if (!projectSelected) {
      return;
    }

    const projectBelongsToUser = projectState.allProjects.find(
      (el) => el.id == parseInt(projectIdParam)
    );

    if (projectIdParam && sectorIdParam && projectBelongsToUser) {
      dispatch(projectActions.setSector(parseInt(sectorIdParam)));
      dispatch(projectActions.setProject(parseInt(projectIdParam)));

      const setSectorCallback = () => {
        dispatch(projectActions.setSector(parseInt(sectorIdParam)));
        dispatch(
          projectActions.setProps({
            ...projectState.props,
            loadSectors: !projectState.props.loadSectors,
            loadConstraints: projectState.props.loadSectors
              ? !projectState.props.loadSectors
              : true
          })
        );
      };
      timerManager.registerAutoTimeout(setSectorCallback, 800, 'setSector');
    }

    projectIdParam &&
      sectorIdParam &&
      !projectBelongsToUser &&
      setUserIsOwner(false);
  }, [projectIdParam, sectorIdParam]);

  useEffect(() => {
    if (!projectSelected) {
      return;
    }

    const projectBelongsToUser = projectState.allProjects.find(
      (el) => el.id == parseInt(projectIdPlan)
    );

    if (projectIdPlan && sectorIdPlan && projectBelongsToUser) {
      dispatch(projectActions.setSector(parseInt(sectorIdPlan)));
      dispatch(projectActions.setProject(parseInt(projectIdPlan)));

      const setSectorCallback = () => {
        dispatch(projectActions.setSector(parseInt(sectorIdPlan)));
        dispatch(
          projectActions.setProps({
            ...projectState.props,
            loadSectors: !projectState.props.loadSectors
          })
        );
      };
      timerManager.registerAutoTimeout(setSectorCallback, 800, 'setSector1');
    }

    projectIdPlan &&
      sectorIdPlan &&
      !projectBelongsToUser &&
      setUserIsOwner(false);
  }, [projectIdPlan, sectorIdPlan]);

  // redirects
  useEffect(() => {
    const signed = localStorage.getItem('signed');
    if (!signed) {
      window.location = '/login';
      return;
    }

    if (!projectState.projectSelected) {
      if (projectIdParam) {
        projectSelected = projectState.allProjects.find(
          (el) => el.id == projectIdParam
        );
        dispatch(projectActions.setProject(parseInt(projectIdParam)));
      } else if (projectIdPlan) {
        projectSelected = projectState.allProjects.find(
          (el) => el.id == projectIdPlan
        );
        dispatch(projectActions.setProject(parseInt(projectIdPlan)));
      } else {
        window.location = '/projects';
      }
    }
  }, []);

  return (
    <span data-testid={TestIds.LOOKAHEAD_MAIN_VIEW_CONTAINER}>
      <Animated
        animationIn="fadeIn"
        animationInDuration={500}
        isVisible={true}
        style={{ height: height - 72, overflow: 'auto' }}>
        <ViewsProvider>
          <LookaHeadSubmodulesMenu
            project={project}
            projectSelected={projectSelected}
            userIsOwner={userIsOwner}
            lookaheadPermissions={lookaheadPermissions}
            history={history}
            t={t}
          />
          {userIsOwner && lookaheadPermissions ? (
            <Switch>
              {projectIdPlan &&
                sectorIdPlan &&
                (hidePlanification ? (
                  <div></div>
                ) : (
                  <PlanificationView
                    t={t}
                    history={history}
                    setContainerHeader={setContainerHeader}
                    permission={lookaheadPermissions.plan}
                  />
                ))}
              <Route path={`${match.path}/planification`}>
                {hidePlanification ? (
                  <div></div>
                ) : (
                  <PlanificationView
                    t={t}
                    history={history}
                    setContainerHeader={setContainerHeader}
                    permission={lookaheadPermissions.plan}
                    hidePlanification={hidePlanification}
                  />
                )}
              </Route>
              <Route path={`${match.path}/resources`}>
                <ResourcesView
                  t={t}
                  history={history}
                  setContainerHeader={setContainerHeader}
                  permission={lookaheadPermissions.resources}
                />
              </Route>
              {projectIdParam && sectorIdParam && (
                <ConstraintsView
                  t={t}
                  history={history}
                  setContainerHeader={setContainerHeader}
                  permission={lookaheadPermissions.constraints}
                  idsParam={idsParam}
                />
              )}
              <Route path={`${match.path}/constraints`}>
                <ConstraintsView
                  t={t}
                  history={history}
                  setContainerHeader={setContainerHeader}
                  permission={lookaheadPermissions.constraints}
                />
              </Route>
            </Switch>
          ) : (
            <Alert
              className="alert-wide"
              message={t('no_permissions')}
              type="error"
            />
          )}
        </ViewsProvider>
      </Animated>
    </span>
  );
}

export default withTranslation()(LookaheadMainView);

import { useState } from 'react';
import {
  getVisibleTaskIds,
  navigateToExportRoute,
  trackLookaheadPdfExport
} from '../utils/dailyPdfExportUtils';
import { activityService } from '../../../../services';
import { sentryLogger } from '../../../../monitor/monitor';

export const useLookaheadDailyPdfExportValidation = ({
  activities,
  modelReportDateLookAhead,
  numWeeks,
  defaultView,
  history,
  config
}) => {
  const [isLoadingPdf, setLoadingPdf] = useState(false);
  const [isShowAlertEmpty, setIsShowAlertEmpty] = useState(false);
  const [isErrorForm, setIsErrorForm] = useState(false);

  const validateAndNavigate = async () => {
    setLoadingPdf(true);
    setIsShowAlertEmpty(false);
    setIsErrorForm(false);

    const trackEventProps = {
      startDate: modelReportDateLookAhead,
      weeksNumber: numWeeks
    };

    try {
      const data = await activityService.getLookaheadWeeklyPlan(config);
      if (!data) {
        setLoadingPdf(false);
        return;
      }

      const visibleIds = activities.map((activity) => activity.id);
      const activitiesInRange = data.activities;

      const idsInRange = activitiesInRange.map((activity) => activity.id);
      const filteredIds = idsInRange.filter((id) => visibleIds.includes(id));
      const visibleTaskIds = getVisibleTaskIds(activities);

      const noTasksInRange = filteredIds.length === 0;

      if (noTasksInRange) {
        trackLookaheadPdfExport('rejected', trackEventProps);
        setIsShowAlertEmpty(true);
        setIsErrorForm(true);
        setLoadingPdf(false);
        return;
      }

      trackLookaheadPdfExport('generated', trackEventProps);
      localStorage.setItem('idsPdfLookahead', JSON.stringify(visibleTaskIds));

      const { colorSchema = 'subcontract' } = defaultView.current;
      const formattedDate = modelReportDateLookAhead.replace(/\//g, '-');

      if (formattedDate && colorSchema && numWeeks) {
        navigateToExportRoute({
          history,
          formattedDate,
          colorSchema,
          numWeeks
        });
      } else {
        sentryLogger.error('Invalid params in navigateToExportRoute', {
          formattedDate,
          colorSchema,
          numWeeks
        });
      }

      setLoadingPdf(false);
      setIsShowAlertEmpty(false);
      setIsErrorForm(false);
    } catch (error) {
      setLoadingPdf(false);
      setIsErrorForm(false);
      sentryLogger.error(
        'Error in useLookaheadDailyPdfExportValidation',
        error
      );
    }
  };

  return {
    isLoadingPdf,
    setLoadingPdf,
    isShowAlertEmpty,
    isErrorForm,
    setIsErrorForm,
    validateAndNavigate
  };
};
